import React from "react"
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

import SEO from "../components/seo"

const NotFoundPage = ({ data }) => (
  <>
    <SEO title="404: Not found" />
    <Container maxWidth="lg">
      <Typography variant="h4">NOT FOUND</Typography>
      <Typography variant="body1">
        You just hit a route that doesn't exist... the sadness.
        <br />
        <Link to="/">Return to civilization.</Link>
      </Typography>
      <Img
        fluid={data.cover.childImageSharp.fluid}
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          paddingTop: "20px",
        }}
      />
    </Container>
  </>
)

export const query = graphql`
  query {
    cover: file(relativePath: { eq: "picard-facepalm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`

export default NotFoundPage
